import {FC, useEffect, useMemo} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useConsumerStore} from "store/consumerStore";
import {observer} from "mobx-react";
import {AuthService} from "service/AuthService";
import {RequestStatus} from "type/request-status";
import {browserRedirect} from "../../utils";
import {CMSSection, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import {HttpService} from "../../service/HttpService";
import {useCMSStore} from "../../store/cmsStore";

export const AuthCheckProvider: FC = observer(props => {
    const { consumerInfo, status, updateProfile } = useConsumerStore();
    const location = useLocation();

    const { structure } = useCMSStore();
    const heroSection: CMSSection | undefined = useMemo(() => structure?.header?.sections?.find(e => e?.type === 'HERO_SECTION'), [structure]);
    const isNavbarFixed = !!heroSection && heroSection?.enabled;

    useEffect(() => {
        if(AuthService.authorized) {
            localStorage.removeItem('queryParameters');
            updateProfile();
        }
    }, [location.pathname]);

    /**
     * If not authorized redirect to /
     */
    if (
        status === RequestStatus.ERROR || !AuthService.authorized
    ) {
        AuthService?.logoutClean();
        browserRedirect(getNavPathFromType(NavigationTypes.PUBLIC_HOME));
    }

    if (!consumerInfo && status === RequestStatus.PENDING) {
        return <></>; // TODO loading
    }

    if (!consumerInfo &&  status === RequestStatus.SUCCESS) {
        return <Navigate to='/genericError' />;
    }

    if(isNavbarFixed){
        return (
            <div className={'jpage-fixed'}>
                {
                    props.children || ''
                }
            </div>
        );
    }

    return (
        <>
            {
                props.children || ''
            }
        </>
    );

})


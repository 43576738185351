import { useMemo } from "react";
import "../style.scss";
import { useTranslationWord, generatePathUrl } from "../../../utils";
import { GenericError, GenericErrorProps } from "../GenericError";
import { CMSConfiguration } from "@jnext/commons";
import { useCMSStore } from "store/cmsStore";

export const ErrorServer = () => {
    const translateWord = useTranslationWord();

    const { pages } = useCMSStore();
    const config: CMSConfiguration | undefined = useMemo(() => pages?.serverError, [pages]);

    const data: GenericErrorProps = useMemo(() => {
        return {
            title: config && config.enabled ? config?.page?.title : translateWord('statusErrorTitle'),
            text: config && config.enabled ? config?.page?.text : translateWord('statusErrorText'),
            image: config?.enabled ? (config?.page?.banner?.image) : [{src: generatePathUrl('assets/error/illustrazioneManutenzione.png')}],
        }
    }, [config]);

    return <GenericError
        title={data.title}
        text={data.text}
        image={data.image}
    />
}

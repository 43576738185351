import {
    AnalyticsActionsEnum,
    AnalyticsEnum,
    AnalyticsEventsEnum,
    CustomAnalyticEventPayload,
    FormOutput,
    getNavPathFromType,
    JCMSCTAProps,
    NavigationTypes,
    RedemptionFormOutput
} from '@jnext/commons';
import { Col, Form, notification } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useMemo, useState } from 'react';
import { AuthService } from 'service/AuthService';
import {
    ProductContexts,
    ProductFormSection,
    ProductFormSections,
    ProductFormSectionTypes as PCFormSectionTypes,
} from '../../../models';
import { ProductContentItem } from '../../ProductContent';
import { AttrbutesForm } from './AttributesForm';
import { FormCTA } from './FormCTA';
import './FormSection.scss';
import { QuantityInput } from './QuantityForm';
import { RedemptionModesForm } from './RedemptionModesForm';
import { CartService } from "../../../../../service/CartService";
import { BasketTypeEnum } from "@jnext/ts-axios-formatdigitalcollection";
import { useLoadingStore } from "../../../../../store/loadingStore";
import { mzUseNavigate, trackingEventsMaps, useTranslationWord } from "../../../../../utils";
import { useWalletStore } from "../../../../../store/walletStore";

export interface FormSectionProps {
    item: ProductContentItem,
    section: ProductFormSection,
    onChangeProduct: (productLogicId: string) => void,
    context: ProductContexts
    initiativeLogicId: string;
}

function filterFormSections(): (value: ProductFormSections) => boolean {
    return s => s?.enabled !== false;
}

export function getMinAvailability(availibility?: number | null, obtainableQuantity?: number | null, availabilityThreshold?: number | null) {
    const arr: any[] = [availibility, obtainableQuantity, availabilityThreshold].filter(a => a !== undefined && a !== null);
    if (arr?.length === 0) {
        return null
    }
    return Math.min(...arr);
}


export const FormSection: FC<FormSectionProps> = observer((props) => {

    const { wallet } = useWalletStore();
    const { setLoading } = useLoadingStore();
    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();
    const [quantity, setQuantity] = useState<number>(1);
    const [canAddToCart, setCanAddToCart] = useState<boolean>(false);

    //set availability props
    const maxProduct: number = 10;
    const maxAvailabilitySelect = useMemo(() => getMinAvailability(props.item.availability, props.item.obtainableQuantity, maxProduct), [props])
    const maxAvailabilityDisplay = useMemo(() => getMinAvailability(props.item.availability, props.item.obtainableQuantity, props.item.availabilityThreshold), [props])

    const formSections = useMemo(() => props.section.sections?.filter(filterFormSections()), [])

    const purchaseEnabled = useMemo(() => {
        return !!(props.item.availability !== 0 && props.item.purchaseEnabled && AuthService.authorized)
    }, [props.item.purchaseEnabled, props.item.availability])

    const onValuesChange = (changedFields: FormOutput) => {
        if (changedFields['data']['attributes']) {
            props.onChangeProduct(changedFields['data']['attributes'] as string)
        }
    }
    const showRedemptionModesForm = useMemo(() => (
        purchaseEnabled && props.item.redemptionModalities && props.item.redemptionModalities?.length > 0 && props.context === ProductContexts.COLLECTION
    ), [purchaseEnabled])

    const showQuantityForm = useMemo(() => (
        purchaseEnabled && maxAvailabilitySelect !== 0 && props.context === ProductContexts.COLLECTION
    ), [purchaseEnabled])

    const formCTA = useMemo(() => {
        const ctaList = props.section.cta
        for (const cta of ctaList) {
            if (cta.scope === 'REDEEM') {
                (cta as JCMSCTAProps).action = async () => {

                    const commonAnalyticsPayload = {
                        event: AnalyticsEventsEnum.REDEEM_REWARD_CTA,
                        category: AnalyticsEventsEnum.REDEEM_REWARD_CTA,
                        action: AnalyticsActionsEnum.CTA,
                        name: "REDEEM_CTA",
                        value: props.item.title ?? props.item.shortDescription ?? props.item.longDescription ?? props.item.externalId ?? props.item.logicId ?? "No product info"
                    }

                    const analyticsEventPayload: CustomAnalyticEventPayload = [
                        {
                            analyticType: AnalyticsEnum.MATOMO,
                            ...commonAnalyticsPayload
                        },
                        {
                            analyticType: AnalyticsEnum.GOOGLE,
                            ...commonAnalyticsPayload
                        }
                    ];

                    trackingEventsMaps(AnalyticsEventsEnum.REDEEM_REWARD_CTA, analyticsEventPayload)
                    //setRedeemModal(true)ù
                    // Can add to cart
                    // On click add item to cart
                    if (await addToCart(BasketTypeEnum.DirectPurchase, props.item.redemptionModalities?.[0]?.logicId!, 1, canAddToCart)) {
                        // Redirect to checkout
                        await navigate(getNavPathFromType(NavigationTypes.CHECKOUT, {
                            initiativeLogicId: props.initiativeLogicId,
                            basketType: BasketTypeEnum.DirectPurchase
                        }));
                    }


                }
            }
        }
        return ctaList
    }, [])

    /**
     * Normal cart add
     * @param values
     */
    const onFormSubmit = async (values: FormOutput<RedemptionFormOutput>) => {
        const canAddToCart = await canAddToCartFunc();
        setCanAddToCart(canAddToCart)
        if(values?.data){
            trackingEventsMaps(AnalyticsEventsEnum.ADD_TO_CART_CTA);
            if (await addToCart(BasketTypeEnum.BasketPurchase, values.data?.redemptionModality?.toString(), parseInt(quantity?.toString()), canAddToCart)) {

                notification.success({
                    message: translateWord('PRODUCT_ADDED_TO_CART_TITLE'),
                    description: translateWord('PRODUCT_ADDED_TO_CART'),
                    onClick: () => {
                    },
                });

            }
        }else {
            notification.error({
                message: translateWord('modalErrorTitle'),
                description: translateWord('modalErrorContent'),
                onClick: () => {
                },
            });
        }
    }

    /**
     * Get basket
     * @param basketType
     */
    const getBasket = async (basketType: BasketTypeEnum) => {

        try {
            return await CartService.getCart(props.initiativeLogicId, basketType);

        } catch (e) {
            // No basket exists
            return undefined
        }

    }


    async function canAddToCartFunc() {
        let canAddToCart = false;
        let redemptionModality = props.item.redemptionModalities?.filter(el => wallet.find(w => w.logicId === el.walletConfigurationLogicId))[0];
        if (redemptionModality) {
            canAddToCart = CartService.canAddToCart(await getBasket(BasketTypeEnum.BasketPurchase), wallet, redemptionModality, quantity);
        }
        return canAddToCart
    }

    /**
     * Add item to cart
     * @param basketType
     * @param redemptionModalityLogicId
     * @param quantity
     * @param canAddToCart
     */
    const addToCart = async (basketType: BasketTypeEnum, redemptionModalityLogicId: string, quantity: number, canAddToCart: boolean): Promise<boolean> => {
        // Set loader
        setLoading(true);
        try {
            // Get wallet for redemption
            const redemptionModality = props.item.redemptionModalities?.find(el => el.logicId == redemptionModalityLogicId);

            if (!redemptionModality && basketType == BasketTypeEnum.BasketPurchase) {
                notification.error({
                    message: translateWord('PRODUCT_ADD_TO_CART_ERROR_TITLE'),
                    description: translateWord('PRODUCT_ADD_TO_CART_ERROR'),
                    onClick: () => {
                    },
                });

                throw new Error('Wallet logic id check error');
            }

            if (basketType == BasketTypeEnum.BasketPurchase && !!redemptionModality && !canAddToCart) {
                notification.error({
                    message: translateWord('PRODUCT_NOT_ENOUGH_CREDIT_TITLE'),
                    description: translateWord('PRODUCT_NOT_ENOUGH_CREDIT'),
                    onClick: () => {
                    },
                });

                throw new Error('Insufficient balance');
            }

            await CartService.add(props.initiativeLogicId, basketType, {
                productLogicId: props.item.logicId,
                redemptionModalityLogicId,
                quantity,
            });
            setLoading(false);

        } catch (e) {
            setLoading(false);
            notification.error({
                message: translateWord('PRODUCT_ADD_TO_CART_ERROR_TITLE'),
                description: translateWord('PRODUCT_ADD_TO_CART_ERROR'),
                onClick: () => {
                },
            });
            return false;
        }

        return true;
    }

    return (
        <Form className='product-form' onValuesChange={onValuesChange} onFinish={onFormSubmit}>
            {
                formSections?.map((section, i) => {
                    let element: JSX.Element = <span></span>
                    switch (section.type) {
                        case PCFormSectionTypes.PRODUCT_FORM_REDEMPTIONS:
                            if (showRedemptionModesForm) {
                                element = (
                                    <RedemptionModesForm
                                        redemptionModalities={props.item.redemptionModalities || []}
                                        section={section}
                                        currencyCode={props.item.catalog?.currency}
                                        key={i}
                                    />
                                )
                            }
                            break;
                        case PCFormSectionTypes.PRODUCT_FORM_ATTRIBUTES:
                            element = (
                                <AttrbutesForm attributes={props.item.attributes || []}
                                               relatedProducts={props.item.relatedProducts || []} key={i} />
                            )
                            break;
                        case PCFormSectionTypes.PRODUCT_FORM_QUANTITY:
                            if (showQuantityForm) {
                                element = (<>
                                        {/* <QuantityForm section={section} availability={props.item.availability} key={i}/>*/}
                                        <QuantityInput
                                            quantity={quantity}
                                            setQuantity={setQuantity}
                                            section={section}
                                            maxAvailabilitySelect={maxAvailabilitySelect}
                                            maxAvailabilityDisplay={maxAvailabilityDisplay}
                                            key={i} />
                                    </>
                                )
                            }
                            break;
                        default:
                            break;
                    }
                    return (
                        <Col span={24} key={i}>
                            {element}
                        </Col>
                    )
                })
            }

            <FormCTA
                cta={formCTA}
                quantity={quantity}
                maxAvailabilitySelect={maxAvailabilitySelect}
                purchaseEnabled={purchaseEnabled}
                productType={props.item.productType} />

        </Form>
    )
})
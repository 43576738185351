import React from 'react';
import './MessageBox.scss';

interface MessageBoxProps {
    msgTitle: string;
    msgSubtitle?: string;
    msgBody?: string;
    msgTitleColor?: string;
    msgSubtitleColor?: string;
    msgBodyColor?: string;
    msgDisclaimerColor?: string;
    msgLogoUrl?: string;
    fromDate: string;
    toDate: string;
}

const MessageBox: React.FC<MessageBoxProps> = ({
                                                   msgTitle,
                                                   msgSubtitle,
                                                   msgBody,
                                                   msgTitleColor,
                                                   msgSubtitleColor,
                                                   msgBodyColor,
                                                   msgDisclaimerColor = "#ebf3fa",
                                                   msgLogoUrl,
                                                   fromDate,
                                                   toDate,
                                               }) => {
    const now = new Date();
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const isVisible = now >= from && now <= to;

    return (isVisible ?
            <div className="message-box" style={{backgroundColor: msgDisclaimerColor, color:"red"}}>
                {msgLogoUrl ? (
                    <img
                        src={msgLogoUrl}
                        alt="Icon"
                        className="icon"
                    />
                ) : (
                    <span className="icon-placeholder">i</span>
                )}
                <div className="content">
                    <h1 style={{color: msgTitleColor}}>{msgTitle}</h1>
                    {msgSubtitle && (
                        <p style={{color: msgSubtitleColor}}>{msgSubtitle}</p>
                    )}
                    <p style={{color: msgBodyColor}}>{msgBody}</p>
                </div>
            </div> : null
    );
};

export default MessageBox;

import {Stepper} from "../../commons/Stepper";
import React, {useEffect, useMemo, useState} from "react";
import {CheckoutCMSConfig, CheckoutDataInsertOptions, CheckoutMainSectionTypes, CheckoutSectionTypes} from "./models";
import {CheckoutService} from "../../service/CheckoutService";
import {getNavPathFromType, JBreadcrumb, JPage, JSection} from "@jnext/commons";
import {StepperSectionTypes} from "./stepperModel";
import {CartService} from "../../service/CartService";
import {CheckoutComplete} from "./Steps/CheckoutComplete";
import {DataFormContainer} from "./Steps/DataForm";
import {DataSummary} from "./Steps/DataSummary";
import {useParams} from "react-router-dom";
import {
    BasketResponse,
    BasketTypeEnum,
    CheckoutRequest,
    FormatCourierDto,
    GetBasketResponseItem,
    ProductTypeEnum
} from "@jnext/ts-axios-formatdigitalcollection";
import {mzUseNavigate} from "../../utils";
import {useCMSStore} from "../../store/cmsStore";
import {useConsumerStore} from "../../store/consumerStore";
import MetaTags from "../../components/MetaTags/MetaTags";
import {useWalletStore} from "../../store/walletStore";

export const Checkout = () => {

    const {consumerInfo} = useConsumerStore();

    const {basketType: basketTypeParam} = useParams();

    const basketType = useMemo(() => {

        if (
            [BasketTypeEnum.DirectPurchase, BasketTypeEnum.BasketPurchase].indexOf(basketTypeParam as BasketTypeEnum) == -1
        ) {
            return BasketTypeEnum.DirectPurchase;
        }

        return basketTypeParam;
    }, [basketTypeParam]) as BasketTypeEnum;

    /**
     * Flag -> if is direct purchase
     */
    const isDirectPurchase = useMemo(() => basketType == BasketTypeEnum.DirectPurchase, [basketType]);

    const {pages} = useCMSStore();
    const cmsConfig: CheckoutCMSConfig = useMemo(() => pages?.checkout as CheckoutCMSConfig, [pages]);
    const checkoutWithCourier = useMemo(() => cmsConfig?.page?.options?.checkoutWithCourier, [cmsConfig?.page]);
    const showDeliveryDaysInfo = useMemo(() => cmsConfig?.page?.options?.showDeliveryDaysInfo, [cmsConfig?.page]);
    const genericDeliveryDays = useMemo(() => cmsConfig?.page?.options?.genericDeliveryDays, [cmsConfig?.page]);

    /**
     * If not enabled
     */
    if (!cmsConfig?.page?.enabled) {
        return <></>;
    }

    /**
     * Sections
     */
    const neededSection = useMemo(() => cmsConfig?.section?.sections?.find(el => el.type == (isDirectPurchase ? CheckoutMainSectionTypes.DIRECT_CHECKOUT : CheckoutMainSectionTypes.BASKET_CHECKOUT)), [cmsConfig]);

    const dataSection = useMemo(() => neededSection?.sections?.find(el => el.type == CheckoutSectionTypes.DATA_INSERT), [neededSection])
    const confirmSection = useMemo(() => neededSection?.sections?.find(el => el.type == CheckoutSectionTypes.CONFIRM), [neededSection]);
    const summarySection = useMemo(() => neededSection?.sections?.find(el => el.type == CheckoutSectionTypes.SUMMARY), [neededSection])
    const stepperSection = useMemo(() => neededSection?.sections?.find(el => el.type == StepperSectionTypes.STEPS), [neededSection])
    const dataSectionOptions = (dataSection?.options ?? null) as CheckoutDataInsertOptions | null;

    /**
     *  Navigation & stepper utils
     */
    const navigate = mzUseNavigate();
    const { updateWallet } = useWalletStore();
    const [activeStep, setActiveStep] = useState<number>(0);

    const [basket, setBasket] = useState<BasketResponse>();
    const isPhysical = useMemo(() => basket?.items?.find(el => el.productType == ProductTypeEnum.Item), [basket?.items]);
    const {initiativeLogicId} = useParams();

    /** Data form State **/
    const [dataFormState, setDataFormState] = useState<CheckoutRequest>();
    const [redemptionId, setRedemptionId] = useState<string>('');

    const [prefilled, setPrefilled] = useState(false);

    /** Couriers **/
    const [couriersList, setCouriersList] = useState<FormatCourierDto[] | undefined>([]);

    const config = useMemo(() => pages?.checkout, [pages]);

    /**
     * Action after filling form data
     * @param formData
     */
    const afterFormFill = async (formData: CheckoutRequest) => {
        if (isDirectPurchase) {
            // Do checkout request
            try {
                const checkoutResponse = await CheckoutService.doCheckout(initiativeLogicId!, basketType, formData);
                
                if (checkoutResponse?.redemptionLogicId) {
                    await updateWallet();
                    setActiveStep(2);
                    setRedemptionId(checkoutResponse?.redemptionLogicId);
                }

            } catch (e) {
            }

        } else {
            setActiveStep(1);
        }

    }

    const afterDataSummary = async () => {
        // Do checkout request

        try {
            const checkoutResponse = await CheckoutService.doCheckout(initiativeLogicId!, basketType, dataFormState!);

            if (checkoutResponse?.redemptionLogicId) {
                await updateWallet();
                setActiveStep(2);
                setRedemptionId(checkoutResponse?.redemptionLogicId);
            }

        } catch (e) {

        }
    }

    /**
     * Remove item from cart
     * @param item
     */
    const removeBasketItem = async (item: GetBasketResponseItem) => {
        await CartService.remove(item, initiativeLogicId!, basketType);

        // Reload all basket data
        await reloadBasket();
    }

    /**
     * Reload basket data
     */
    const reloadBasket = async () => {
        const cartResponse = await CartService.getCartPreview(initiativeLogicId!, basketType);
        setBasket(cartResponse);
        return cartResponse;
    }


    /**
     * Extract cart
     */
    useEffect(() => {
        (async () => {

            const cartResponse = await reloadBasket();
            const isItems = cartResponse?.shipments?.find(item => item.productType === 'ITEM');
            const couriers = cartResponse?.couriers;

            if(cartResponse && checkoutWithCourier && !!isItems){
                if(couriers && couriers?.length > 0){
                    setCouriersList(cartResponse?.couriers);
                    setDataFormState(prevState =>{
                            return{
                                ...prevState,
                                shippingCourier : {
                                    courierExternalId: couriers[0].externalId,
                                    flDeliveryScheduled: couriers[0].partners?.[0].flScheduledDelivery
                                }
                            }
                        }
                    );}
            }

            /**
             * If not data in basket -> return to homepage
             */
            if (cartResponse?.items?.length == 0) {
                await navigate(getNavPathFromType('HOME'));
                return;
            }
        })();
    }, []);

    /**
     * prefill user email
     */
    useEffect(() => {
        if (!consumerInfo || !consumerInfo.email || !dataSectionOptions || !dataSectionOptions.prefillUserData) {
            return;
        }
        
        setDataFormState(prev => {
            return {
                ...prev,
                address: {
                    ...prev?.address,
                    email: consumerInfo.email
                }
            }
        });

        setPrefilled(true);

    }, [consumerInfo?.email, dataSectionOptions?.prefillUserData]);

    /**
     * Auto-submit digital form
     */
    useEffect(() => {

        if (!dataSectionOptions || !dataSectionOptions.fastDigitalCheckout || !prefilled || !isDirectPurchase || isPhysical || !dataFormState) {
            return;
        }

        afterFormFill(dataFormState).then()

    }, [prefilled, dataSectionOptions?.fastDigitalCheckout, isDirectPurchase, isPhysical, dataFormState])

    return <>
        {
            cmsConfig &&
            <JPage {...cmsConfig} title=''>
                <MetaTags pageConfiguration={config?.page}/>
                <JSection>
                    <div className={'checkout-page'}>

                        <div className={'checkout-page-content'}>
                            <aside>
                                {
                                    cmsConfig?.page?.title &&
                                    <div>
                                        <JBreadcrumb items={[
                                            {
                                                label: 'Home',
                                                navigateType: 'HOME'
                                            },
                                            {
                                                label: cmsConfig?.page?.title
                                            }
                                        ]}/>
                                    </div>
                                }
                                <h1 className={'aside-title'}>{cmsConfig?.page?.title}</h1>
                                {
                                    stepperSection?.sections &&
                                    <Stepper steps={
                                        stepperSection.sections?.map(el => ({
                                            title: el.title || '',
                                        }))
                                    }
                                             activeStep={activeStep}
                                             disabled={activeStep >= 2}
                                             onChange={(newStep) => setActiveStep(newStep)}
                                    />
                                }
                            </aside>
                            <div className={'content-side'}>

                                {/* Step 1: form */}
                                {
                                    activeStep == 0 &&
                                    dataSection && basket &&
                                    <DataFormContainer
                                        cmsConfig={dataSection}
                                        onComplete={async (formData) => {
                                            setDataFormState(prev => {
                                                return {
                                                    ...prev, address: formData.address
                                                }
                                            });
                                            await afterFormFill(formData);
                                        }}
                                        basketType={basketType}
                                        initiativeLogicId={initiativeLogicId!}
                                        basket={basket}
                                        initialValues={dataFormState}
                                        showDeliveryDaysInfo={showDeliveryDaysInfo}
                                        genericDeliveryDays={genericDeliveryDays}
                                    />
                                }

                                {/* Step 2: cart review */}
                                {
                                    activeStep == 1 && basket &&
                                    dataFormState &&
                                    dataSection && confirmSection &&
                                    <DataSummary
                                        cmsConfig={confirmSection}
                                        checkoutWithCourier={checkoutWithCourier}
                                        showDeliveryDaysInfo={showDeliveryDaysInfo}
                                        genericDeliveryDays={genericDeliveryDays}
                                        dataFormCmsConfig={dataSection}
                                        basket={basket}
                                        initiativeLogicId={initiativeLogicId!}
                                        removeItem={(item) => removeBasketItem(item)}
                                        dataForm={dataFormState!}
                                        completeCheckout={() => afterDataSummary()}
                                        onCheckoutDataUpdate={(formState => {
                                            setDataFormState(prevState => {
                                                return {
                                                    ...prevState,
                                                    address: formState.address || prevState?.address,
                                                    shippingCourier: formState.shippingCourier || prevState?.shippingCourier
                                                }
                                            });
                                        })}
                                        couriersList={couriersList}
                                    />
                                }

                                {/* Step 3: order complete and summary */}
                                {
                                    activeStep == 2 &&
                                    summarySection && redemptionId && basket &&
                                    <CheckoutComplete
                                        cmsConfig={summarySection}
                                        redemptionId={redemptionId}
                                        basket={basket}
                                        showDeliveryDaysInfo={showDeliveryDaysInfo}
                                        genericDeliveryDays={genericDeliveryDays}
                                    />
                                }

                            </div>
                        </div>
                    </div>
                </JSection>
            </JPage>
        }
    </>;
}
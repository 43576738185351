import {Col, DatePicker, Row} from 'antd';
import {CMSSection, JButton, JRangePicker} from "@jnext/commons";
import {useState} from "react";
import {LeftOutlined} from "@ant-design/icons";
import 'moment/locale/it';
import locale from 'antd/es/date-picker/locale/it_IT';
import {FORMAT_DATE, TranslateComponent, useTranslationWord} from "../../../../utils";
import {useWalletStore} from "../../../../store/walletStore";
import {WalletPoints} from "@jnext/ts-axios-wallet";
import moment from "moment";

const { RangePicker } = DatePicker;

export default({ walletLogicId, setWalletLogicId, period, setPeriod, closeFilters, cms}:
                   {walletLogicId?: string, setWalletLogicId:(walletLogicId: string | undefined) => Promise<any>,
                                   period?: {transactionDateStart:string, transactionDateEnd: string},
                       setPeriod:(p: {
                           transactionDateStart: any;
                           transactionDateEnd: any
                       }) => Promise<any>,
                       closeFilters: () => void, cms: CMSSection}) => {
    const translateWord = useTranslationWord();
    const { wallet } = useWalletStore();



    return(
        <Row className="filter"  align="middle" gutter={[32,12]}>
            <Col span={24}>
                <div className={'backButton'} onClick={closeFilters}>
                    <LeftOutlined /> &nbsp; {translateWord('goBack')}
                </div>
            </Col>
            <Col span={24}>
                <TranslateComponent className="titleFilter" text="filter"/>
            </Col>
            {wallet &&  <Col className="walletStyle" sm={24} md={16}>
                {wallet?.map((wall: WalletPoints , i:number)=>{
                    if(wall?.cmsConfig?.title){
                        return(
                            <JButton
                                key={i}
                                className={(wall?.walletLogicId === walletLogicId) ? 'active' : ''}
                                type='default'
                                onClick={()=> {
                                    setWalletLogicId(wall?.walletLogicId).then(wall => console.log(wall?.cmsConfig?.title) );
                                }}>
                                {wall?.cmsConfig?.title}
                            </JButton>
                        )
                    }
                })}
                <JButton
                    className={(walletLogicId=== 'all') ? 'active' : ''}
                    type='default'
                    onClick={() => {
                        setWalletLogicId('all');
                        console.log(cms?.filters?.wallet?.label)
                    }}>
                    {cms?.filters?.wallet?.label}
                </JButton>
            </Col>
            }
            <Col className="selectStyle" sm={24} md={8} xl={6}>
                <JRangePicker
                    showTime={false}
                    format={FORMAT_DATE}
                    label={translateWord('DATES_RANGE')}
                    onChange={(period: any) => {
                        const transactionDateStart =  period?.[0]?.format();
                        const transactionDateEnd = period?.[1]?.format();
                        setPeriod({transactionDateStart, transactionDateEnd})
                    }}
                    placeholder={[cms?.filters?.date?.placeholder?.[0] || '', cms?.filters?.date?.placeholder?.[1] || '']}
                    locale={locale}
                />
            </Col>
        </Row>
    );
}
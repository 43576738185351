import { NavigationType, NavigationTypes, getNavPathFromType } from "@jnext/commons";
import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useState } from "react";
import { useConsumerStore } from "store/consumerStore";
import MicroApp from "../../commons/MicroApp";
import { LoginType } from "../../enums/LoginType";
import { AuthService } from "../../service/AuthService";
import { HttpService } from "../../service/HttpService";
import { browserRedirect, mzUseNavigate } from "../../utils";

export interface FinalizeProps {
    type: 'sso-finalize' | 'web-finalize'
}
const Finalize: FC<FinalizeProps> = observer(props => {
    const navigate = mzUseNavigate();
    const { updateProfile } = useConsumerStore();
    const [ready, setReady] = useState<boolean>(false);
    const token = useMemo(() => AuthService?.accessToken, []);

    useEffect(() => {
        // Remove old user data
        AuthService.logoutClean();

        // Set as ready to load
        setReady(true);

    }, []);



    // if token not preset exit
    if (!token) {
        browserRedirect(getNavPathFromType(NavigationTypes.PUBLIC_HOME));
    }

    return (
        <>
            {
                ready && HttpService.env?.coreUser && (
                    <MicroApp
                        entry={`${HttpService.env?.coreUser}`}
                        props={{
                            type: props.type,
                            onSuccess: async () => {

                                AuthService.setToken(AuthService.accessToken)
                                if (props.type === 'sso-finalize') {
                                    AuthService.setLoginType(LoginType.sso);
                                } else {
                                    AuthService.setLoginType(LoginType.standard);
                                }
                                await updateProfile()

                                browserRedirect(getNavPathFromType(NavigationTypes.PRIVATE_HOME));
                            },
                            onError: (error: unknown) => {
                                if (error) {
                                    navigate(getNavPathFromType(NavigationTypes.NO_AUTH));
                                }
                            },
                            onNavigate(type: NavigationType) {
                                return navigate(type)
                            },
                            authorized: true,

                            // Parent have no token. Pass token to headers object from queryParams
                            httpHeaders: HttpService.getHttpHeaders({ token }),
                        }}
                    />)
            }
        </>
    )
})


export default Finalize;
import React, { useState } from "react";
import "./";
import { Dropdown } from "antd";
import { observer } from "mobx-react";
import {ProjectLanguageDto} from "@jnext/ts-axios-coreconfiguration";
import Flag from "react-flagkit";
import {useAppStore} from "../../store/appStore";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {DEFAULT_LANGUAGE} from "../../type/languages";

enum LanguageMenu {
    IT = 'IT',
    EN = 'GB'
}

const menu = (languages: ProjectLanguageDto[] | undefined, setFlag: any) => {
    return !!languages?.length && languages?.map((language: ProjectLanguageDto, i: number) => {
        return {
            label: (
                <div
                    key={i}
                    className="lnDisp"
                    onClick={() => {
                        if(language?.cd_lng){
                            setFlag(language?.cd_lng);
                            localStorage.setItem("ln", language?.cd_lng?.toLowerCase());
                            location.reload();
                        }
                    }}
                >
                    {language?.cd_lng && <Flag country={LanguageMenu[language?.cd_lng as keyof typeof LanguageMenu]}/>}
                    {language?.name}
                </div>
            ),
            key: language?.defaultLanguage ? language?.cd_lng : '',
        };
    })
};


export const Language = observer(() => {
        const {languages} = useAppStore();

        if(!languages?.length || languages?.length === 0) {
            return <></>
        }

        const defaultLng: ProjectLanguageDto | undefined = languages?.find((language: ProjectLanguageDto)=> language?.defaultLanguage);
        let ln = localStorage.getItem("ln") || defaultLng?.cd_lng || DEFAULT_LANGUAGE;
        const [flag, setFlag] = useState<string>(ln || '');

        return (
            <Dropdown
                className="languageContainer"
                menu={
                    {
                        items: menu(languages, setFlag) as any
                    }
                }
                placement="bottom"
                trigger={['click', 'hover']}
                arrow
            >
                <div className="languageSection">
                    {flag && <Flag country={LanguageMenu[flag?.toUpperCase()  as keyof typeof LanguageMenu]}/>}
                    <DownOutlined className='downOutline' />
                    <UpOutlined className='upOutline'/>
                </div>
            </Dropdown>
        );
    }
);

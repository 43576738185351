import React from "react";
import {FORMAT_DATE, generatePathUrl, useTranslationWord} from "../../utils";
import {OrderOrderStatusEnum, RedemptionResponseStatusEnum} from "@jnext/ts-axios-mz";
import moment from "moment";


export interface FooterCardV2Props {
    status: OrderOrderStatusEnum | RedemptionResponseStatusEnum | undefined;
    options?: any;
    orderDate?: string;
}


function getRowStatus(status: OrderOrderStatusEnum | RedemptionResponseStatusEnum | undefined, options?: any, orderDate?: string | undefined) {
    const statusOption = options;
    switch (status) {
        case OrderOrderStatusEnum.Inserted:
        case OrderOrderStatusEnum.Approved: {
            return (<div className={'footerCard waiting'}>
                <div className={'statusInfo'}>
                    <img src={generatePathUrl(statusOption?.waiting?.image[0]?.src)} aria-hidden={true}/> {statusOption?.waiting?.text}
                </div>
                <div className={'textInfo'}>
                    {moment(orderDate)?.format(FORMAT_DATE)}
                </div>
            </div>)
        }
        case RedemptionResponseStatusEnum.WaitingForUser:{
            return (<div className={'footerCard waiting'}>
                <div className={'statusInfo'}>
                    <img src={generatePathUrl(statusOption?.waitingForUser?.image[0]?.src)} aria-hidden={true}/> {statusOption?.waitingForUser?.text}
                </div>
                <div className={'textInfo'}>
                    {moment(orderDate)?.format(FORMAT_DATE)}
                </div>
            </div>)
        }
        case OrderOrderStatusEnum.StockIssue: {
            return (<div className={'footerCard stock'}>
                <div className={'statusInfo'}>
                    <img src={generatePathUrl(statusOption?.stock?.image[0]?.src)} aria-hidden={true}/> {statusOption?.stock?.text}
                </div>
                <div className={'textInfo'}>
                    {moment(orderDate)?.format(FORMAT_DATE)}
                </div>
            </div>)
        }
        case OrderOrderStatusEnum.Sent: {
            return (<div className={'footerCard sent'}>
                <div className={'statusInfo'}>
                    <img src={generatePathUrl(statusOption?.sent?.image[0]?.src)} aria-hidden={true}/> {statusOption?.sent?.text}
                </div>
                <div className={'textInfo'}>
                    {moment(orderDate)?.format(FORMAT_DATE)}
                </div>
            </div>)
        }
        case OrderOrderStatusEnum.Delivered: {
            return (<div className={'footerCard delivered'}>
                <div className={'statusInfo'}>
                    <img src={generatePathUrl(statusOption?.delivered?.image[0]?.src)} aria-hidden={true}/> {statusOption?.delivered?.text}
                </div>
                <div className={'textInfo'}>
                    {moment(orderDate)?.format(FORMAT_DATE)}
                </div>
            </div>)
        }
        case OrderOrderStatusEnum.Annulled: {
            return (<div className={'footerCard annulled'}>
                <div className={'statusInfo'}>
                    <img src={generatePathUrl(statusOption?.annulled?.image[0]?.src)} aria-hidden={true}/> {statusOption?.annulled?.text}
                </div>
                <div className={'textInfo'}>
                    {moment(orderDate)?.format(FORMAT_DATE)}
                </div>
            </div>)
        }
        case OrderOrderStatusEnum.Expired:
        default: return (<div className={'footerCard expired'}>
            <div className={'statusInfo'}>
                <img src={generatePathUrl(statusOption?.expired?.image[0]?.src)} aria-hidden={true}/> {statusOption?.expired?.text}
            </div>
            <div className={'textInfo'}>
                {moment(orderDate)?.format(FORMAT_DATE)}
            </div>
        </div>)
    }
}

export const FooterCardV2: React.FC<FooterCardV2Props> = ({
                                                              status,
                                                              options,
                                                              orderDate
                                                          }) => {

    return (
        <>
            {getRowStatus(status, options, orderDate)}
        </>
    );
}

import React, {useEffect, useMemo, useState} from "react";
import {CatalogService} from "../../service/CatalogService";
import {GetSectorsResponse} from "@jnext/ts-axios-formatdigitalcollection";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {useWindowSize} from "@react-hook/window-size";
import {breakpointsMax} from "@jnext/commons";
import {Media} from "../ProductDetail/ProductMedia";

interface CategoriesProps {
    onChoose: (sectorId: string | undefined) => void;
    initiativeLogicId: string;
    isPrivate: boolean;
}

export const Categories:React.FC<CategoriesProps> = ({onChoose, initiativeLogicId, isPrivate}) => {
    const [categories, setCategories] = useState<GetSectorsResponse>();
    const [activeCategory, setActiveCategory] = useState<string>();
    const [width] = useWindowSize();
    const isMobileLayout = useMemo(() => (
        width < breakpointsMax.sm
    ), [width])
    const [openFilter, setOpenFilter]= useState<boolean>(isMobileLayout);
    const [openSubFilter, setOpenSubFilter]= useState<any[]>([]);

    useEffect(() => {

        (async () => {

            /** Load categories **/
            const data = await CatalogService.getSectors(initiativeLogicId, isPrivate);
            setCategories(data);
        })();
    }, []);

    useEffect(() => {
        setOpenFilter(isMobileLayout)
    }, [isMobileLayout]);


    useEffect(() => {
        if(categories){
            let openSubFilterTMP: any[] = [];
            [categories]?.map((parent, key) => {
                if(parent?.childs && parent?.childs?.length > 0){
                    openSubFilterTMP.push({logicId: parent.logicId, isOpen: false})
                }
                parent.childs?.map((child, key) => {
                    if(child?.childs && child?.childs?.length > 0){
                        openSubFilterTMP.push({logicId: child.logicId, isOpen: false})
                    }
                })
            })
            setOpenSubFilter(openSubFilterTMP)
        }
    }, [categories]);

    /**
     * On active category choose
     * @param childs
     */
    useEffect(() => {
        onChoose(activeCategory);
    }, [activeCategory]);

    const hasActiveChild = (childs?: GetSectorsResponse[]): boolean => {
        return !!childs && childs?.find(child => child.logicId == activeCategory || hasActiveChild(child?.childs)) !== undefined;
    }

    function chooseFilter(child: GetSectorsResponse, logicId: string | undefined){
        if(logicId){
            let newOpenSubFilter = openSubFilter.find(open => open.logicId === logicId);
            if(child?.childs?.length === 0 || (child?.childs && !newOpenSubFilter?.isOpen)){
                setActiveCategory(child.logicId)
            }
            if(child?.childs && child?.childs?.length){
                newOpenSubFilter.isOpen = !newOpenSubFilter?.isOpen;
                setOpenSubFilter([...openSubFilter, newOpenSubFilter]);
            }
        }
    }


    const renderTree = (childs?: GetSectorsResponse[]) => {
        return !!childs && childs.map((child) => {
            let newOpenSubFilter = openSubFilter.find(open => open.logicId === child?.logicId);
            return(
                <li key={child.logicId}
                    className={`
                    ${hasActiveChild(child.childs) ? 'open' : ''} 
                    ${child.logicId == activeCategory ? 'active' : ''}
                `}>
                    <span onClick={()=>chooseFilter(child, child.logicId)}>{child.title}</span>

                    {child?.childs && child?.childs?.length > 0 &&
                        <>
                            {!newOpenSubFilter?.isOpen &&
                                <UpOutlined
                                    style={{marginLeft: '0.5rem'}}
                                    onClick={()=>chooseFilter(child,  child.logicId)}/>}

                            {newOpenSubFilter?.isOpen &&
                                <DownOutlined
                                    style={{marginLeft: '0.5rem'}}
                                    onClick={()=>chooseFilter(child,  child.logicId)} />}
                        </>}

                    {
                        child.childs && newOpenSubFilter?.isOpen &&
                        <ul className={'sublevel'}>
                            {renderTree(child.childs)}
                        </ul>
                    }
                </li>
            )
        });
    }

    return (
        <>
            <div className={'categories-box'}>
                <div className={'categories-title'}>Categorie</div>
                <div className={'categoriesOutLined'} onClick={()=>setOpenFilter(!openFilter)}>
                    {!openFilter && <UpOutlined/>}
                    {openFilter && <DownOutlined/>}
                </div>
            </div>
            {
                categories  && !openFilter &&
                <ul>
                    <li className={`${activeCategory == undefined ? 'active' : ''}`}>
                        <span onClick={() => {
                            setActiveCategory(undefined)
                            setOpenSubFilter([]);
                        }}>Tutte le categorie</span>
                    </li>
                    {renderTree([categories])}
                </ul>
            }
        </>
    );
}
import { NavigationTypes, getNavPathFromType } from "@jnext/commons";
import { GetConsumerDto } from "@jnext/ts-axios-coreuser";
import { SsoConsumerResponseDto } from "@jnext/ts-axios-coreuser/dist/models/sso-consumer-response-dto";
import { useEffect } from "react";
import { NavigationType } from "react-router-dom";
import { useConsumerStore } from "store/consumerStore";
import MicroApp from "../../../commons/MicroApp";
import { LoginType } from "../../../enums/LoginType";
import { AuthService } from "../../../service/AuthService";
import { HttpService } from "../../../service/HttpService";
import { browserRedirect, getCluster, mzUseNavigate } from "../../../utils";


const SSOCheck = () => {
    const navigate = mzUseNavigate();
    const { updateProfile } = useConsumerStore();

    useEffect(() => {
        // Remove old user data
        AuthService.logoutClean();
    }, []);

    // get url tokens
    const { project, tenant, token, refreshToken } = AuthService.parseUrlTokens();

    // if token not preset exit
    if (!token)
    {
        browserRedirect(getNavPathFromType(NavigationTypes.PUBLIC_HOME));
    }

    const parseJwt = (token: string) => {
        try
        {
            return JSON.parse(atob(token.split('.')[1]));
        }
        catch (e)
        {
            return null;
        }
    };

    const saveSsoConsumer = (ssoConsumerDto: SsoConsumerResponseDto | undefined) => {
        const decodedToken = parseJwt(token);
        AuthService.setToken(ssoConsumerDto?.access_token);
        AuthService.setRefreshToken(refreshToken);
        AuthService.timerRefreshToken(decodedToken?.exp ?? 300);
    }

    return (
        <>
            {HttpService.env?.coreUser && (
                <MicroApp
                    entry={`${HttpService.env?.coreUser}`}
                    props={{
                        type: 'sso-check',
                        onRedirect: async (type: any, ssoConsumerDto: SsoConsumerResponseDto | undefined) => {
                            switch (type) {
                                case 'home':
                                    // @ts-ignore
                                    const { consumerDto } = ssoConsumerDto;
                                    AuthService.setLoginType(LoginType.sso);
                                    saveSsoConsumer(ssoConsumerDto);

                                    if (ssoConsumerDto?.consumerDto) {
                                        AuthService.saveProfileFields({
                                            name: consumerDto?.name,
                                            surname: consumerDto?.surname,
                                            clusterName: consumerDto?.categoryProfile?.clusterLogicId,
                                            clusterId: getCluster(ssoConsumerDto?.consumerDto as GetConsumerDto)?.clusterCategoryLogicId,
                                        });
                                    }
                                    browserRedirect(getNavPathFromType(NavigationTypes.PRIVATE_HOME));
                                    break;
                                case 'finalization': {
                                    localStorage.setItem('tmp_refresh_token', refreshToken || '');
                                    AuthService.setTempToken(ssoConsumerDto?.access_token);
                                    await updateProfile();
                                    // pass tokens to finalize
                                    await navigate(getNavPathFromType(NavigationTypes.SSO_FINALIZE));
                                }
                                    break;

                                case 'consumerError':
                                    await navigate(getNavPathFromType(NavigationTypes.NO_AUTH));
                                    break;
                            }
                        },
                        onNavigate(type: NavigationType) {
                            return navigate(type)
                        },
                        authorized: true,
                        keyCloakClientId: HttpService.env?.keycloakClientID as string,
                        // Parent have no token. Pass token to headers object from queryParams
                        httpHeaders: HttpService.getHttpHeaders({ token }),
                    }} />)
            }
        </>
    )
}


export default SSOCheck;
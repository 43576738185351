import React, { Suspense, useEffect, useMemo } from "react";
import { observer } from "mobx-react";;
import {browserRedirect, getAppStyle, getParametersUrl, mzUseNavigate} from "../../utils";
import { AuthService } from "../../service/AuthService";
import { TemplateType } from "../../enums/TemplateType";
import { getNavPathFromType, NavigationTypes } from "@jnext/commons";
import { useCMSStore } from "store/cmsStore";
import { useLocation } from "react-router-dom";
import PageLayoutTemplate from "../../components/PageLayoutTemplate";
import {RequestStatus} from "../../type/request-status";
import {HttpService} from "../../service/HttpService";


interface PublicHomeProps {
    checkAuth?: boolean;
}

const PublicHome: React.FC<PublicHomeProps> = ({ checkAuth = true }) => {
    const location = useLocation();
    const navigate = mzUseNavigate();
    const { appSettings } = useCMSStore();

    const templateName = useMemo(() => appSettings?.template?.code, [appSettings]);

    /** Lazy style load **/
    const templateStyle = useMemo(() => getAppStyle(templateName as TemplateType), [templateName]);
    const Style = useMemo(() => templateStyle ? React.lazy(() => templateStyle()) : null, [templateStyle]);

    useEffect(() => {
        getParametersUrl();
        if (checkAuth && AuthService.authorized) {
            if (location.pathname.includes('/products/')) {
                const paramsstr = location.pathname.match(/\/products(.*)/)?.[1] || ''
                const paramsarr = paramsstr.split('/')
                paramsarr.shift()
                browserRedirect(getNavPathFromType(NavigationTypes.PRODUCT_DETAILS, {
                    productId: paramsarr[0],
                    relatedId: paramsarr[1]
                }));
            } else {
                navigate(getNavPathFromType(NavigationTypes.PRIVATE_HOME));
            }
        }
    }, []);

    return (
        <div className="app-public-wrapper">
            <Suspense fallback="">{Style !== null && <Style />}</Suspense>
            <PageLayoutTemplate />
        </div>
    );
};

export default observer(PublicHome);

import React, {Suspense, useEffect, useMemo, useState} from "react";
import {catalogSlickConfig, mzUseNavigate} from "../../utils";
import {
    CMSSection,
    getNavPathFromType,
    JSection,
    JSkeletonSlider,
    JSlider,
    NavigationTypes,
    SLIDER_4X,
} from "@jnext/commons";
import {CatalogService} from "../../service/CatalogService";
import {GetProductsPreviewPagedResponse} from "@jnext/ts-axios-formatdigitalcollection";
import {DigitalWalletCardItem} from "../CatalogList/DigitalWalletCardItem";
import {CatalogType} from "../../type/catalogTypes";
import {AuthService} from "../../service/AuthService";
import {InitiativeDetails, ProductDetails} from "@jnext/ts-axios-mz";

interface Options {
    initiativeLogicId: string,
    page?: number,
    size?: number,
    slidesToScroll?: number
}

function DigitalWalletSlider({ section, initiativeLogicIdProps, walletInitiative, ...others }: {section?: CMSSection, initiativeLogicIdProps?: string, walletInitiative?: ProductDetails }) {

    const navigate = mzUseNavigate();
    const [catalog, setCatalog] = useState<GetProductsPreviewPagedResponse>({results: []});
    const [loaded, setLoaded] = useState<boolean>(false);
    const sectionOptions = section?.options as unknown as Options;
    const obtainableOptions = useMemo(()=> (sectionOptions as any)?.obtainable ?? undefined,[sectionOptions])
    const {initiativeLogicId, page, size, slidesToScroll} = sectionOptions;
    const [logicIdInit, setLogicIdInit] = useState<string>(initiativeLogicId);

    useEffect(() => {
        if(initiativeLogicIdProps) {
            setLogicIdInit(initiativeLogicIdProps);
        }
    }, [initiativeLogicIdProps]);

    useEffect(() => {
        if(walletInitiative){
            setCatalog(walletInitiative as GetProductsPreviewPagedResponse);
            setLoaded(true);
        }else{
            (async () => {
                const data = await CatalogService.getProducts(initiativeLogicId, {}, {
                    page: page ?? 0,
                    pageSize:size ?? 10
                }, AuthService.authorized).finally(()=> setLoaded(true));;
                if(data){
                    setCatalog(data)
                }
            })();
        }
    }, []);


    if (!loaded) {
        return <JSkeletonSlider hasTitle={true} />;
    }
    const openProduct = (productId: string) => {
        const paths = {
            [CatalogType.digitalWallet]: AuthService.authorized ? NavigationTypes.CATALOG_DIGITAL_WALLET_DETAILS : NavigationTypes.PUBLIC_CATALOG_DIGITAL_WALLET_DETAILS
        }
        if(logicIdInit){
            return navigate(getNavPathFromType(paths[CatalogType.digitalWallet], {initiativeLogicId: logicIdInit, productId }));
        }
    }

    return (
        <>
            {
                !!section && section?.enabled && <Suspense fallback={null}>
                    <>
                        {!!catalog?.results && catalog?.results?.length > 0 && <JSection {...section}>
                            <JSlider {...catalogSlickConfig(SLIDER_4X, {slidesToScroll: slidesToScroll})}>
                                {catalog.results?.map((el, i) => (
                                    <DigitalWalletCardItem
                                        key={el.logicId}
                                        isDigital
                                        obtainableOptions={obtainableOptions}
                                        data={el}
                                        pricePosition={'TOPRIGHT'}
                                        showTag={true}
                                        onClick={() => openProduct(el?.logicId as string)}
                                        navigationUrl={getNavPathFromType(NavigationTypes.CATALOG_DIGITAL_WALLET_DETAILS, {initiativeLogicId: logicIdInit, productId: el?.logicId })}
                                    />
                                ))}
                            </JSlider>
                        </JSection>}
                    </>
                </Suspense>
            }
        </>
    );
}

export default DigitalWalletSlider;

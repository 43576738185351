import {HttpService} from "./HttpService";
import axios from "axios";
import {PaginatedResponse} from "../type/pagination";
import {WalletPoints} from "@jnext/ts-axios-wallet";
import getSymbolFromCurrency from "currency-symbol-map";
import {CatalogType} from "../type/catalogTypes";
import {
    DigitalCollectionApi,
    GetFilterValuesResponse,
    GetSectorsResponse, LiquidationTypeEnum,
    ProductPreviewDto, ProductPreviewRedemptionModalityDto
} from "@jnext/ts-axios-formatdigitalcollection";
import {GetLimitsResponse} from "@jnext/ts-axios-formatdigitalcollection";
import {ArrayOfInitiativeModuleDto} from "@jnext/ts-axios-coreconfiguration";

export interface GetProductsFilters {
    searchText?: string,
    tagLogicIds?: string[],
    brandLogicIds?: string[];
    walletConfigurationLogicIds?: string[];
    sectorLogicIds?: string[],
    minAmount?: number,
    maxAmount?: number,
}

interface CatalogRequestPagination {
    page: number,
    pageSize: number,
}

export interface ProductCost {
    cost?: {
        value: number;
        originalValue?: number;
        currency: string;
    }
    points?: {
        value: number;
        originalValue?: number;
        image?: string;
    }
}

export interface LocalStorageCatalogData {
    initiatives: ArrayOfInitiativeModuleDto;
}

export class CatalogService {

    /**
     * Get product list
     * @param initiativeLogicId
     * @param filters
     * @param pagination
     * @param isPrivate
     */
    static async getProducts(initiativeLogicId: string, filters: GetProductsFilters, pagination: CatalogRequestPagination, isPrivate: boolean = true): Promise<PaginatedResponse<ProductPreviewDto[]>> {
        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);



        // campi del sotring
        // type sortFields =  "brandName" | "cardTitle" | "selfPoints" | "sellPrice" | "selfCash" | "pointsPrice";
        const res = isPrivate ?
            await instance.getProductsPreviewPrivate(
                pagination.page,
                pagination.pageSize,
                initiativeLogicId,
                true,
                false,
                [],
                filters?.searchText || '',
                filters?.tagLogicIds || [],
                filters?.brandLogicIds || [],
                filters?.walletConfigurationLogicIds || [],
                filters?.sectorLogicIds || [],
                filters?.minAmount,
                filters?.maxAmount
            ) :
            await instance.getProductsPreviewPublic(
                pagination.page,
                pagination.pageSize,
                initiativeLogicId,
                true,
                false,
                [],
                filters?.searchText || '',
                filters?.tagLogicIds || [],
                filters?.brandLogicIds || [],
                filters?.sectorLogicIds || [],
                filters?.minAmount,
                filters?.maxAmount
            );

        return {
            results: res?.data?.results!,
            pagination: {
                pageCount: parseInt(res?.headers?.['page-count']),
                recordsCount: parseInt(res?.headers?.['records-count']!),
                page: pagination?.page,
            }
        };
    }

    /**
     * Get sectors/categories
     */
    static async getSectors(initiativeLogicId: string, isPrivate = true): Promise<GetSectorsResponse> {

        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance[isPrivate ? 'getSectorsPrivate' : 'getSectorsPublic' ](initiativeLogicId);

        return res?.data as any;
    }

    /**
     * Get brand list for catalog
     */
    static async getBrands(initiativeLogicId: string, isPrivate: boolean = true): Promise<GetFilterValuesResponse[]> {

        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = isPrivate ? (await instance.getBrandsPrivate(initiativeLogicId)) : await instance.getBrandsPublic(initiativeLogicId);

        return res?.data;
    }

    /**
     * Get tag list for catalog
     */
    static async getTags(initiativeLogicId: string, isPrivate: boolean = true): Promise<GetFilterValuesResponse[]> {

        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = isPrivate ? (await instance.getTagsPrivate(initiativeLogicId)) : await instance.getTagsPublic(initiativeLogicId);

        return res?.data;
    }

    static async getLimits(initiativeLogicId: string): Promise<GetLimitsResponse> {
        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance.getLimits(initiativeLogicId);

        return res?.data;
    }

    static getPrice(redemptionModality: ProductPreviewRedemptionModalityDto, wallets: WalletPoints[]): ProductCost {

        const currency = 'EUR';
        const currencySymbol = getSymbolFromCurrency(currency) || currency;

        // Get wallet
        const wallet = wallets?.find(wallet => wallet?.logicId?.toLowerCase() === redemptionModality?.walletConfigurationLogicId?.toLowerCase());
        const image = wallet?.cmsConfig?.imageRef;

        const returnObj: ProductCost = {}

        switch (redemptionModality?.type) {

            // Points, acquistabile solo con punti
            case LiquidationTypeEnum.Points:

                if (redemptionModality?.finalPointsPrice) {
                    returnObj.points = {
                        value: redemptionModality.finalPointsPrice,
                        originalValue: redemptionModality.pointsPrice,
                        image,
                    }
                }

                break;


            // Sell, acquistabile con valuta
            case LiquidationTypeEnum.Sell:
                if (redemptionModality?.finalSellPrice) {
                    returnObj.cost = {
                        value: redemptionModality.finalSellPrice,
                        currency: currencySymbol,
                        originalValue: redemptionModality.sellPrice,
                    }
                }
                break;

            // Self, acquistabile con combinazione cash/points
            case LiquidationTypeEnum.Self:

                if (redemptionModality?.finalSelfCash) {
                    returnObj.cost = {
                        value: redemptionModality.finalSelfCash,
                        currency: currencySymbol,
                        originalValue: redemptionModality.selfCash,
                    }
                }

                if (redemptionModality?.finalSelfPoints) {
                    returnObj.points = {
                        value: redemptionModality.finalSelfPoints,
                        originalValue: redemptionModality.selfPoints,
                        image,
                    }
                }

                break;
        }

        return returnObj;
    }


    static saveCatalogInfo(type: CatalogType, data: LocalStorageCatalogData) {
        localStorage.setItem(`catalog_${type}`, JSON.stringify(data));
    }

    static getCatalogInfo(type: CatalogType): LocalStorageCatalogData | null {
        const data = localStorage.getItem(`catalog_${type}`);

        if (!data) {
            return null;
        }

        try {
            return JSON.parse(data);
        } catch (e) {
            return null;
        }
    }
}